import React from "react";
import { motion } from "framer-motion";


export const Tab = (props) => {

  return (
      <React.Fragment >
        <motion.div>
          {props.children}
        </motion.div> 
      </React.Fragment>
  )
}; 


const TabButtons = ({buttons, changeTab, activeTab}) =>{
  
  return(
    <div className="tab-buttons">
      {buttons.map((button, index) =>{
        return ( 
          <motion.button 
            key={`tab-` + index} 
            transition={{ duration: 0.1}} 
            style={{ color: button === activeTab ? '#0E1487' : '', borderBottom: button === activeTab ? '2px solid #0E1487' : ''}} 
            className={button === activeTab ? 'active': ''} 
            onClick={()=> changeTab(button)}
          >
            {button}
          </motion.button>
        )
      })}
    </div>
  )
}

export class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label
  }

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };


  render() {
    let content; 
    let buttons = []; 
   
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label); 
          if(child.props.label === this.state.activeTab) {
            content = child.props.children
          };
        })}

        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
        <div className="tab-note">
          <p className="first">Rent your next awesome <b> apartment </b> easily with</p>
          <p className="second"> your <b> smart phone  </b></p>
        </div>
          <div className="panel" >
              <React.Fragment>
                {content}
              </React.Fragment>
          </div>
      </div>


      
    )
  }
}