import React from "react";
import { Link } from "react-router-dom";
import RightIcon from "../assets/RightIcon"; 
import { NavLinks } from "../data";




const Hamburger = () => {
  const [isActive, setIsActive] = React.useState(false); 

  const openMenu = () => setIsActive(!isActive); 

  const closeMenu = () => setIsActive(false);

  React.useEffect(() => {
    if(!isActive){
      openMenu();
    }
  }, []); 

  return (
    <>
      <div className={`hamburger ${isActive ? '' : 'open'}`} onClick={openMenu}>
        <div className="burger-icon"></div>

        <div className={`hamburger-links-container ${isActive ? 'change' : ''}`}>
          <div className={`hamburger-links`}>
            <ul>
              {NavLinks.map((val, index) =>  (
                <React.Fragment key={index}>
                  <li key={`hamburger` + index}>
                    <Link onClick={closeMenu}>{val.name}</Link>
                    <RightIcon />
                  </li>
                  <hr />
                </React.Fragment>
              ))}

            </ul>
          </div>
        </div>
      </div>


    </>
  )
}; 


export default Hamburger; 