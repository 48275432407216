import Human from "./assets/Prince.svg"; 


export const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@settleappafrica"; 

export const myImg= "https://cdn-images-1.medium.com/fit/c/150/150/1*aRaZkVpP7AjucnJ3HTL_rQ.jpeg"


export const imgs = [
  {
    id: 1,
    name: 'Peter-Williams',
    title: 'Co-Founder', 
    src: Human
  },
  {
    id: 2,
    name: 'Peter-Williams',
    title: 'Head Of Design', 
    src: Human
  },
  {
    id: 3,
    name: 'Peter-Williams',
    title: 'Co-Founder', 
    src: Human
  },
  {
    id: 4, 
    name: 'Peter-Williams',
    title: 'Co-Founder', 
    src: Human
  },
  {
    id: 5,
    name: 'Peter-Williams',
    title: 'Co-Founder', 
    src: Human
  }
]; 



export const questionsAnswers = [
  {
    question: "What is Settle Africa?",
    answer:
      "Settle streamlines the rental journey from search to stay by offering a user-friendly platform that consolidates property listings, facilitates easy communication with landlords, and provides secure transaction features for a hassle-free experience.",
  },
  {
    question: "How do I list my apartments?",
    answer:
      "Settle prioritizes community and user comfort. We foster a sense of belonging, ensuring our users not only find their ideal homes but also connect with a supportive community. Our emphasis on simplicity and personalized experiences distinguishes us.",
  },
  {
    question: "Can I get a refund after payment?",
    answer: 
      "Yes, Settle operates in various locations, offering a diverse range of rental options. Our platform is designed to cater to the housing needs of individuals across different cities, making it convenient for users in their apartment search.",
  },
  {
    question: "How do I know the tenants have been vetted properly?",
    answer: 
      "Security is paramount. Settle employs advanced encryption and authentication measures to safeguard user transactions. Our platform ensures a secure environment for financial interactions, providing peace of mind to our users.",
  },
  {
    question: "Can I communicate directly with landlords through Settle?",
    answer:
      "Absolutely. Settle facilitates direct communication between tenants and landlords through our messaging feature. This direct line of communication ensures clarity, transparency, and a smoother rental process for both parties involved."
  }, 
  {
    question: "How can i reach out to settle support team?", 
    answer: 
      "You can reach out to the settle customer service team via our official number, +2348146893873 and via our support email: hello@settleafrica.com. Our hotlines and emails are available 8 am - 6 pm, Mon - Sat."
  }
];


export const features = [
  {
    question: 'House Listing',
    answer: "Save time and hassle with Settle Africa's professional house listing services."
  }, 

  {
    question: 'Home Rentals', 
    answer: "Choose from a wide range of apartments in different locations and price ranges to find the perfect fit for you."
  }, 

  {
    question: 'Community', 
    answer: "Join Settle to connect with users nearby and beyond. Discover shared experiences, embark on adventures, and build a vibrant community together!", 
    disabled: true
  },

  {
    question: 'Bills Payments', 
    answer: "We Make it easy and straightforward to pay various bills with our bill payment feature", 
    disabled: true
  }, 

  {
    question: 'Thrift Store', 
    answer: "Find and purchase unique items using our self-sustaining thrift store", 
    disabled: true
  }
]; 



export const NavLinks = [
  {
    name: "About us",
    to: "us"
  }, 

  {
    name: "Product", 
    to: "product"
  }, 

  {
    name: "Services",
    to: 'services'
  }, 

  {
    name: "FAQ",
    to: "faq"
  },

  {
    name: "Contact Us",
    to: "https://forms.gle/TTjL77jmGbG9CLZPA"
  },

  {
    name: "Privacy",
    to: "https://www.settleafrica.com/privacy_policy"
    // to: "http://localhost:3000/privacy"
  }
]; 

export const footerAccordion = [
  {
    question: 'Company'
  }, 

  {
    question: 'Social', 
  }, 

  {
    question: 'Support'
  }
]
