import React from "react";
import ReactDOM from "react-dom/client";
import { ScrollingProvider } from "react-scroll-section";
// import { BrowserRouter } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";

import "./scss/styles.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/privacy_policy",
    element: <PrivacyPolicy />,
  },

  {
    path: "/request_form",
    element: <ContactUs />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ParallaxProvider scrollAxis="vertical">
      <ScrollingProvider>
        <RouterProvider router={router} />
      </ScrollingProvider>
    </ParallaxProvider>
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//       <ParallaxProvider scrollAxis='vertical'>
//         <BrowserRouter>
//           <ScrollingProvider>
//           <App />
//           </ScrollingProvider>
//         </BrowserRouter>
//       </ParallaxProvider>
// );
