


const FeatherRight = ({ className }) => {
  return (
    <svg className={className} width="171" height="161" viewBox="0 0 171 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.6765 40.1332C29.9378 37.1925 44.6696 34.0784 61.5064 45.1479C82.5523 58.9848 90.2976 59.4691 110.898 59.3818C131.499 59.2944 136.699 51.3854 159.31 73.3549" stroke="#AB70F2" strokeWidth="9.62564" strokeLinecap="round"/>
      <path d="M20.3796 64.1879C25.641 61.2471 40.3727 58.1331 57.2095 69.2026C78.2554 83.0394 86.0007 83.5238 106.602 83.4365C127.202 83.3491 132.402 75.4401 155.013 97.4096" stroke="#AB70F2" strokeWidth="9.62564" strokeLinecap="round"/>
      <path d="M16.0867 88.2435C21.348 85.3028 36.0798 82.1888 52.9165 93.2582C73.9624 107.095 81.7077 107.579 102.309 107.492C122.909 107.405 128.109 99.4958 150.72 121.465" stroke="#AB70F2" strokeWidth="9.62564" strokeLinecap="round"/>
    </svg>
  )
}; 

export default FeatherRight; 