import React from "react";
import { motion, useScroll, useSpring, useTransform} from "framer-motion"; 
import ResizeObserver from "resize-observer-polyfill";


const SmoothScroll = ({ children }) => {
  const scrollRef = React.useRef(null); 

  const [ pageHeight, setPageHeight ] = React.useState(100); 

  const resizePageHeight = React.useCallback((entries) => {
    for(let entry of entries) {
      setPageHeight(entry.contentRect.height); 
    }
  }, []); 


  React.useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => resizePageHeight(entries)); 
    scrollRef && resizeObserver.observe(scrollRef.current); 
    return () => resizeObserver.disconnect(); 
  }, [scrollRef, resizePageHeight]);
  
  
  const { scrollY } = useScroll(); 

  const transform = useTransform(scrollY, [0, pageHeight], [0, -pageHeight]); 
  const spring = useSpring(transform, { 
    damping: 15, 
    mass: 0.27, 
    stiffness: 55
  }); 


  return (
    <>
      <motion.div ref={scrollRef} style={{ y: spring }} className="scroll-container">
        {children}
      </motion.div>
      <div style={{ height: pageHeight - 50}} />
    </>

  )
}; 


export default SmoothScroll; 