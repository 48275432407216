import React from "react";
import { FaApple, FaAndroid, FaTruckLoading  } from "react-icons/fa";
import Loader from "./Loader"; 
import Card from "./Card";
import { mediumURL } from "../data";



const AllCards = () => {
  const [mediumData, setMediumData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);


  React.useEffect(() => {
    setIsLoading(true); 
    setError(null); 

    fetch(mediumURL)
      .then((res) => res.json())
      .then((response) => {
        setMediumData(response.items); 
        setIsLoading(false); 
      })
      .catch((err) => {
        setError(err); 
        setIsLoading(false); 
      })
  }, []); 




  return (
    <>
      {isLoading && <p style={{ textAlign: 'center'}}>Fetching data from Medium...</p>}
      {error && <p style={{ textAlign: 'center'}}>There was an error fetching the data: {error.message}</p>}
      {!isLoading && !error && (
        <div className="flex-cards">
          <>
            {mediumData.slice(0, 5).map((article) => {
              return (
                <Card article={article}/>
              )
            })}
          
          </>
        </div>
      )}

    </>

  )
}; 


export default AllCards;