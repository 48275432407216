import React from "react";


const useStickyHeader = (ref, observeSettings = { threshold: [1]}) => {
  const [isSticky, setSticky] = React.useState(false); 
  const newRef = React.useRef() 
  ref ||= newRef; 


  React.useEffect(() => {
    
    window.addEventListener('scroll', () => {
      const currentScroll = window.scrollY; 
      if(currentScroll >= 150){
        setSticky(true)
      }else {
        setSticky(false); 
      }
    })


    window.removeEventListener('scroll', () => {
      setSticky(false); 
    })
  }, [newRef, ref]); 


  return [isSticky, ref, setSticky]; 
}; 


export default useStickyHeader; 