import React from "react";
import { format, compareAsc } from "date-fns";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import Scroll, { Element, Link as ScrollLink } from "react-scroll";
import {
  Section as ScrollingSection,
  useScrollSection,
  useScrollSections,
} from "react-scroll-section";
import AllCards from "./components/AllCards";
import SmoothScroll from "./components/SmoothScroller";
import Accordion from "./components/Accordion/Accordion";
import { FaApple, FaAndroid } from "react-icons/fa";
import Logo from "./assets/Logo.svg";
import Hero from "./assets/Hero.svg";
import Banner from "./assets/Banner.svg";
import William from "./assets/William.svg";
import Jake from "./assets/Jake.svg";
import Deji from "./assets/Deji.svg";
import BoysAndGirl from "./assets/BoysAndGirls.svg";
import Landlord1 from "./assets/Landlord1.svg";
import Landlord2 from "./assets/Landlord2.svg";
import Chair from "./assets/Chair.svg";
import Thailand from "./assets/bangkok-city-thailand 1.svg";
import Ladies from "./assets/Ladies.svg";
import SettleAfrica from "./assets/SettleAfrica.svg";
import Header from "./components/Header";
import Anchor from "./assets/Anchor";
import FeatherRight from "./assets/FeatherRight";
import FeatherLeft from "./assets/FeatherLeft";
import { features, footerAccordion, questionsAnswers } from "./data";
import { Tabs, Tab } from "./components/Tab";
import { useMediaQuery } from "./hooks/useMediaQuery";

//styles file

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      duration: 1,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  },
};

const App = () => {
  const selected = useScrollSection();
  const [pTag, setpTag] = React.useState({ color: "" });

  const [mediumData, setMediumData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const isMobile = useMediaQuery(
    "screen and (min-width: 1px) and (max-width: 513px)"
  );
  const isTablet = useMediaQuery(
    "screen and (min-width: 514px) and  (max-width: 991px)"
  );

  const handleTextChange = () => {
    if (window.scrollY >= 50) {
      setpTag({ color: "white" });
    } else {
      setpTag({ color: "black" });
    }
  };

  // React.useEffect(() => {
  //     window.addEventListener('scroll', handleTextChange, true);
  //     return () => window.removeEventListener('scroll', handleTextChange);
  // }, [pTag]);

  return (
    <LayoutGroup>
      <AnimatePresence>
        <Header selected={selected} />
        <SmoothScroll>
          <div className="layout">
            <h1>
              Apartment Rentals Made <u>Better</u>
            </h1>
            {!isMobile && !isTablet ? (
              <motion.div className="layout-container">
                <motion.p initial="initial" animate="animate">
                  Our goal is to improve the end-to-end process of renting for
                  tenants and listing apartments for landlords
                </motion.p>

                <p className="download">Download the mobile app</p>

                <div className="btn__container">
                  <button>
                    <div className="btn__cloth">
                      <FaApple size={30} />
                      <span>AppleStore</span>
                    </div>
                  </button>
                  <button className="mobile__mt">
                    <div className="btn__cloth">
                      <FaAndroid size={30} />
                      <span>PlayStore</span>
                    </div>
                  </button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                className="layout-container"
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeatDelay: 1,
                }}
              >
                <motion.p
                  initial="initial"
                  animate="animate"
                  variants={letterAni}
                >
                  Our goal is to improve the end-to-end process of renting for
                  tenants and listing apartments for landlords
                </motion.p>

                <p className="download">Download the mobile app</p>

                <div className="btn__container">
                  <button>
                    <div className="btn__cloth">
                      <FaApple size={30} />
                      <span>AppleStore</span>
                    </div>
                  </button>
                  <button className="mobile__mt">
                    <div className="btn__cloth">
                      <FaAndroid size={30} />
                      <span>PlayStore</span>
                    </div>
                  </button>
                </div>
              </motion.div>
            )}
          </div>

          <motion.div className="hero">
            <img src={Hero} />

            <h2>Who we are</h2>

            <div>
              <p className="first">
                We are a new age Proptech startup focusing on <b>simplifying</b>{" "}
                the <b>end-to-end process</b> of
              </p>
              <p className="second">
                apartment <b>rentals and house listings</b> with an intended
                venture into the web3 space
              </p>
            </div>
          </motion.div>

          <div className="banner">
            <img src={Banner} />
          </div>

          <Element id="product">
            <div className="product">
              <h2>Product</h2>

              <div className="tabs">
                <Tabs>
                  <Tab label="Tenants">
                    <div className="panel-container">
                      <img className="img-1" src={Ladies} />
                      <FeatherLeft className="feather-left" />
                      <FeatherRight className="feather-right" />
                      <img className="img-2" src={BoysAndGirl} />
                    </div>
                  </Tab>

                  <Tab label="Landlord">
                    <div className="panel-container">
                      <img className="img-1" src={Landlord1} />
                      <FeatherLeft className="feather-left" />
                      <FeatherRight className="feather-right" />
                      <img className="img-2" src={Landlord2} />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Element>

          <Element id="services">
            <div className="offers">
              <div className="not_inner">
                <h2>What we offer</h2>
                <div className="inner">
                  <p>
                    We provide exclusive <b>housing options</b> for tenants at
                    incredibly <b>affordable rates,</b> allowing you to filter
                    and select based on your <b>unique preference.</b>
                  </p>

                  <p className="second">
                    We also ensured a <b>smooth experience</b> for{" "}
                    <b>landlords listing apartments, finding tenants</b> and
                    receiving
                    <b> speedy payments.</b>
                  </p>
                </div>
              </div>

              <div className="accordion">
                <Accordion faqs={features} />
              </div>
            </div>
          </Element>

          <Element id="us">
            <div className="story">
              <h2>
                The Story of Settle Begins with Trust & <u>Security.</u>
              </h2>
              <div className="bg__stunt" />

              <div>
                <p>
                  By being the bridge between landlords and tenants, we pride
                  ourselves in ensuring trust is established and maintained
                  between both parties, by having an improved kyc process and
                  vetting users properly to ensure both parties are secured.{" "}
                </p>

                <p>
                  We have partnered with paystack to ensure timely payments to
                  landlords, which is done every 24hours. Tenants also benefit
                  by having a shorter waiting period between payments and
                  confirmation.
                </p>
              </div>
            </div>
          </Element>

          <div className="app-section">
            <div className="cover">
              <img src={Chair} />
              <div className="cover-overlay">
                <div className="test">
                  <h2>
                    We’ve got you covered on your next <u>listing</u> and{" "}
                    <u>rental.</u>
                  </h2>
                </div>

                <p>Download the mobile app</p>

                <div className="group__btn">
                  <button>
                    <div className="btn__cloth">
                      <FaApple size={30} className="icon" />
                      <span>Apple Store</span>
                    </div>
                  </button>
                  <button style={{ marginLeft: "2rem" }}>
                    <div className="btn__cloth">
                      <FaAndroid className="icon" size={30} />
                      <span>Play Store</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/** blog */}
          <Element id="blog">
            <div className="blog">
              <div className="blog-intro">
                <h2>Our Blog</h2>
                <p>
                  We’re excited to share amazing news, incites about our
                  services and milestones on our blog site.
                </p>
              </div>

              <div className="flex-images">
                <AllCards />
              </div>
            </div>
          </Element>

          <div className="team">
            <h2>Our team</h2>
            <p>
              Our core team is made up of self driven <b>specialists</b> in
              various fields. From <b>design, development, finance</b> e.t.c
            </p>

            <div className="card__container">
              <div className="row-container" data-speed="slow">
                <div className="img-container">
                  <img src={William} width={200} height={200} />

                  <ul>
                    <li>Peter Williams</li>
                    <li>Co-Founder</li>
                  </ul>
                </div>

                <div className="img-container">
                  <img src={Jake} width={200} height={200} />
                  <ul>
                    <li>Amana Braimah</li>
                    <li>Head Of Design</li>
                  </ul>
                </div>

                <div className="img-container">
                  <img className="img-3" src={Deji} width={200} height={200} />
                  <ul>
                    <li>Deji Oderinde</li>
                    <li>Head Of Operations</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Element id="faq">
            <div className="frequently">
              <h2>Frequently asked questions</h2>

              <div className="accordion">
                <Accordion faqs={questionsAnswers} />
              </div>

              <div className="overlay-container">
                <img src={Thailand} />
                <Anchor className="anchor" />

                <div className="overlay">
                  <h2>Download our app</h2>
                  <p>
                    Join our happy tenants and landlords on the settle app and
                    get introduced to our awesome community
                  </p>

                  <div className="group__btn">
                    <button>
                      <div className="btn__cloth">
                        <FaApple size={30} />
                        <span>Apple Store</span>
                      </div>
                    </button>

                    <button>
                      <div className="btn__cloth">
                        <FaAndroid size={30} />
                        <span>Play Store</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Element>

          <footer>
            <section>
              <div className="footer__identity">
                <img src={Logo} />
                <div className="app__downloader">
                  <h3>Download the mobile app</h3>

                  <div className="btn__group">
                    <button>
                      <div className="btn__cloth">
                        <FaApple size={30} />
                        <span>Apple Store</span>
                      </div>
                    </button>
                    <button>
                      <div className="btn__cloth">
                        <FaAndroid size={30} />
                        <span>Play Store</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className="footer__links">
                <div className="link__container">
                  <ul>
                    <span>COMPANY</span>
                    <li>
                      <ScrollLink to="product" spy={true} duration={500}>
                        Product
                      </ScrollLink>
                    </li>
                    <li>
                      <Link to="#">Career</Link>
                    </li>
                    <li>
                      <Link to="#">Market</Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://medium.com/@settleappafrica"
                        spy={true}
                        offset={50}
                        duration={500}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <ScrollLink
                        to="faq"
                        spy={true}
                        offset={50}
                        duration={500}
                      >
                        FAQ
                      </ScrollLink>
                    </li>
                  </ul>

                  <ul>
                    <span>SOCIAL</span>
                    <li>
                      <Link
                        target="_blank"
                        to="https://twitter.com/officialsettle"
                      >
                        Twitter
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.linkedin.com/company/settle-app"
                      >
                        Linkedin
                      </Link>
                    </li>

                    <li>
                      <Link
                        target="_blank"
                        to="https://www.instagram.com/officialsettle.app/"
                      >
                        Instagram
                      </Link>
                    </li>

                    <li>
                      <Link to="#">Facebook</Link>
                    </li>
                  </ul>

                  <ul>
                    <span>SUPPORT</span>
                    <li>
                      <Link to="#">support@settleafrica.com</Link>
                    </li>
                    <li>
                      <Link to="#">hello@settleafrica.com</Link>
                    </li>
                    <li>
                      <Link to="#"> +234-8146-8938-73</Link>
                    </li>
                  </ul>
                </div>

                <Accordion faqs={footerAccordion} />
              </div>
            </section>

            <div className="footer-description">
              <ul>
                <li>2023 Settle Africa</li>
                <li>Terms</li>
                <li>Privacy Policy</li>
              </ul>
            </div>

            <div className="section__ledger">
              <img src={SettleAfrica} />

              {/** MOBILE */}
              <div className="mobile__logo">
                <h1>Settle</h1>
                <h1>Africa</h1>
              </div>
            </div>
          </footer>
        </SmoothScroll>
      </AnimatePresence>
    </LayoutGroup>
  );
};

export default App;
