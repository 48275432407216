import React, { useState } from "react";
import AccordionItem from "./AccordionItem";


  const Accordion = ({ faqs }) => {
    const [clicked, setClicked] = useState("0");


    const handleToggle = (index) => {
      if (clicked === index) {
        return setClicked("0");
      }
      setClicked(index);
    };

    return (
      <ul className="accordion">
        {faqs.map((faq, index) => (
          <AccordionItem key={'faq-' + index} faq={faq} active={clicked === index} onToggle={() => handleToggle(index)}/>
        ))}
      </ul>
    )
  }; 


export default Accordion; 