


const FeatherLeft = ({ className }) => {
  return (
    <svg className={className} width="174" height="161" viewBox="0 0 174 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6765 40.1332C26.9378 37.1925 41.6696 34.0784 58.5064 45.1479C79.5523 58.9848 87.2976 59.4691 107.898 59.3818C128.499 59.2944 133.699 51.3854 156.31 73.3549" stroke="#E99550" strokeWidth="9.62564" strokeLinecap="round"/>
      <path d="M17.3796 64.1879C22.641 61.2471 37.3727 58.1331 54.2095 69.2026C75.2554 83.0394 83.0007 83.5238 103.602 83.4365C124.202 83.3491 129.402 75.4401 152.013 97.4096" stroke="#E99550" strokeWidth="9.62564" strokeLinecap="round"/>
      <path d="M13.0867 88.2435C18.348 85.3028 33.0798 82.1888 49.9165 93.2582C70.9624 107.095 78.7077 107.579 99.3086 107.492C119.909 107.405 125.109 99.4958 147.72 121.465" stroke="#E99550" strokeWidth="9.62564" strokeLinecap="round"/>
    </svg>
  )
}; 


export default FeatherLeft; 