import React from "react";


const Badge = ({ title }) => {
  return (
    <div className="badge">
      <span>{title }</span>
    </div>
  )
}; 


export default Badge; 