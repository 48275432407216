import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { myImg } from "../data";  
import DefaultImg from "../assets/DefaultImg.svg"; 
import '../scss/card.scss'; 





const Card = ({ article }) => {
  const sanitizedDescription = DOMPurify.sanitize(article.description);
  const descriptionText = sanitizedDescription.replace(/<figcaption>.*<\/figcaption>/gi, '').replace(/<[^>]+>/g, ''); 

  return (
    <>
      <div className='card'>
        <img src={article.thumbnail || DefaultImg} alt=''/>
        
        <div>
          <p className='date'>{ new Date(article.pubDate).toDateString()}</p>
          <Link to={article.link} target='_blank'>
            <h3>{article.title}</h3>
          </Link>
        </div>
      </div>
    </>

  )
}; 

export default Card; 