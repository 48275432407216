import { useRef } from "react";
import Badge from "../Badge";
import UpArrow from "../../assets/UpIcon"; 
import DownArrow from "../../assets/DownIcon"; 

const AccordionItem = ({ faq, active, onToggle }) => {
  
  const { question, answer, disabled } = faq; 
  const contentEl = useRef(); 

  return (
    <li className={`accordion_item ${active ? "active" : ""}`}>
      <div className="button">
        <button onClick={onToggle}>
          <span className="title">
            {question}
          </span>

          { disabled ? (
              <Badge title='Coming Soon'/>
            ): (
              <></>
            )}
    

          <span className="icon">
            {active ? (
              <UpArrow />
            ): (
              <DownArrow />
            )}
          </span>
        </button>
      </div>



      <div
        ref={contentEl}
        className="answer_wrapper"
        style={
          active
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="answer">{answer}</div>
      </div>
      <hr />
    </li>
  )
}

export default AccordionItem;