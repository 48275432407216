import React from "react"; 
import { motion } from "framer-motion";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

import useStickyHeader from "../hooks/useStickyHeader";
import Logo from "../assets/Logo.svg";
import { NavLinks } from "../data";
import Hamburger from "./Hamburger";
import { Link as RouterLink } from "react-router-dom"; // Add this import




const Header = () => {
  const refImg = React.useRef(); 
  const [isSticky, ref, setSticky] = useStickyHeader(); 

  const handleClick = () => {
    scroll.scrollToTop(); 
  }

  return (
    <>
      <motion.header
        initial={{ opacity: 0, y: -180}}
        animate={{ opacity: 1, y: 0}}
        transition={{
          ease: "easeInOut", 
          duration: 1, 
          delay: 0.6
        }}
        id="header"
        className={isSticky ? "is-sticky" : "un-sticky"}
      >
        <nav>
          <img src={Logo} onClick={handleClick} alt="Logo"/>
          <ul>            
            {NavLinks.map((val, index) => (
              <li key={val.name}>
                {val.to.startsWith("http") ? ( // Check if it's an external link
                  <a href={val.to} target="_blank" rel="noopener noreferrer">{val.name}</a>
                ) : (
                  <ScrollLink activeStyle={{ borderBottom: "2px solid rgba(14, 20, 135, 1)"}} spy={true} to={val.to} offset={50} duration={500}>
                    {val.name}                    
                  </ScrollLink>
                )}
              </li>
            ))}
          </ul>
          <Hamburger />
        </nav>
        <hr />
      </motion.header>
    </>
  )
}; 

export default Header;