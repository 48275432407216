
import React from "react";



export function useMediaQuery(...queries) {
  const supportMatchmedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'; //checker; 
  
  const [match, setMatch] = React.useState(false); 

  const query = queries.join(); 

  React.useEffect(() => {
    let active = true; 

    if(!supportMatchmedia) return undefined; 

    const queryList = window.matchMedia(query); 

    function updateMatch() {
      if(active) setMatch(queryList.matches); 
    }

    updateMatch();
    queryList.addListener(updateMatch); 

    return () => {
      active = false, 
      queryList.removeListener(updateMatch)
    }
  }, [query, supportMatchmedia]); 


  return match; 
}; 