import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px 45px",
      }}
    >
      <div>
        <h1 style={{ textDecoration: "underline" }}>Privacy Policy</h1>
      </div>
      <div>
        <p>
          Settle exists to help build connections between people and make the
          world more open and inclusive. In short—to build a community where it
          meets comforts. We are a community built on trust. A fundamental part
          of earning that trust means being clear about how we use your
          information and protecting your human right to privacy.
        </p>
        <p>
          This Privacy Policy describes how Settle App Limited and its
          affiliates (“we,” “us,” or “Settle”), process personal information in
          relation to your use of the Settle Platform. Depending on where you
          live and what you are doing on the Settle Platform, the supplemental
          privacy pages listed below may apply to you. You can find a link to
          the Privacy Policy on the company website via this link,{" "}
          <a href="https://www.setttleafrica.com/privacy">
            https://www.setttleafrica.com/privacy.
          </a>
        </p>
        <ol>
          <li>
            <p>Personal Information We Collect</p>
          </li>
          <li>
            <p>How We Use the Information We Collect</p>
          </li>
          <li>
            <p>Sharing and Disclosure</p>
          </li>
          <li>
            <p>Third-Party Partners and Integrations</p>
          </li>
          <li>
            <p>Your Rights</p>
          </li>
          <li>
            <p>Security</p>
          </li>
          <li>
            <p>Changes to This Privacy Policy</p>
          </li>
          <li>
            <p>Contact Information and Responsible Settle Entities</p>
          </li>
          <li>
            <p>Definitions</p>
          </li>
        </ol>
        <div>
          <p style={{ color: "black", fontWeight: "bold" }}>
            1. PERSONAL INFORMATION WE COLLECT.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.1 Information Needed to Use the Settle Platform.
            </strong>{" "}
            We collect personal information about you when you use the Settle
            Platform. Without it, we may not be able to provide all services
            requested. This information includes:
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.1.1 Contact, Account, and Profile Information.
            </strong>{" "}
            Such as your first name, last name, phone number, location, email
            address, and profile photo, some of which will depend on the
            features you use.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.1.2 Payment Information.
            </strong>{" "}
            Such as payment account or bank account information. If you are not
            a Settle user, we may receive payment information relating to you,
            such as when a Settle user provides your payment card to complete a
            rental.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.2 Information You Choose to Give Us.
            </strong>{" "}
            You can choose to provide us with additional personal information,
            including:
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.2.1 Additional Profile Information.
            </strong>{" "}
            Such as preferred language(s), city, and personal description.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.2.2 Information About Others.
            </strong>{" "}
            Such as a payment instrument or contact information belonging to
            another person or information about a co-tenant. By providing us
            with personal information about others, you certify that you have
            permission to provide that information to Settle for the purposes
            described in this Privacy Policy and you have shared the Settle
            Privacy Policy with them.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.2.3 Other Information.
            </strong>{" "}
            Such as when you fill in a form, add information to your account,
            respond to surveys, post to reviews, participate in refferals,
            communicate with Settle Support and other Members, import or
            manually enter address book contacts, provide your address and/or
            geolocation, or share your experience with us.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3 Information Automatically Collected by Using the Settle
              Platform.
            </strong>{" "}
            When you use the Settle Platform, we automatically collect certain
            information. This information may include:
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3.1 Geolocation Information.
            </strong>{" "}
            Such as precise or approximate location determined from your IP
            address, mobile or other device’s GPS, or other information you
            share with us, depending on your device settings. We may also
            collect this information when you’re not using the app if you enable
            this through your settings or device permissions.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3.2 Usage Information.
            </strong>{" "}
            Such as searches for Listings, bookings you have made, additional
            services you have added, access dates and times, the pages you’ve
            viewed or engaged with before or after using the Settle Platform,
            and other actions on the Settle Platform, including the pages or
            content you view and links you click to third-party applications.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3.3 Device Information.
            </strong>{" "}
            Such as IP address, hardware and software information, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3.4 Cookies and Similar Technologies As Described in Our Cookie
              Policy.
            </strong>
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.3.5 Payment Transaction Information.
            </strong>{" "}
            Such as payment instrument used, date and time, payment amount,
            payment instrument expiration date and billing address, and other
            related transaction details.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.4 Information We Collect from Third Parties.
            </strong>{" "}
            We may collect personal information from other sources, such as:
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.4.1 Complainants.
            </strong>{" "}
            If a Landlord, Tenants, or any third party submits a complaint about
            you, we may receive information relating to the specific complaint
            made in order to understand and, where relevant, address the
            complaint.
          </p>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              1.4.2 Financial Institutions.
            </strong>{" "}
            If you elect to pay with funds from your bank account, we may
            receive certain information from your financial institution, such as
            bank account details and account balance.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              2. HOW WE USE INFORMATION WE COLLECT.
            </strong>{" "}
            We use personal information as outlined in this Privacy Policy.
          </p>

          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              2.1 Provide, Improve, and Develop the Settle Platform.
            </strong>{" "}
            We may process this information to:
          </p>
          <ul>
            <li>
              <p>
                i. enable you to access the Settle Platform and make and receive
                payments,
              </p>
            </li>
            <li>
              <p>ii. enable you to communicate with others,</p>
            </li>
            <li>
              <p>iii. process your request,</p>
            </li>
            <li>
              <p>iv. develop and improve our products and services,</p>
            </li>
            <li>
              <p>v. provide customer service training,</p>
            </li>
            <li>
              <p>
                vi. send you messages, updates, security alerts, and account
                notifications,
              </p>
            </li>
            <li>
              <p>
                vii. process, handle, or assess insurance claims or similar
                claims,
              </p>
            </li>
            <li>
              <p>
                viii. determine your country of residence based on automated
                review of your account information and your interactions with
                the Settle Platform,
              </p>
            </li>
            <li>
              <p>
                ix. personalize and customize your experience based on your
                interactions with the Settle Platform, your search and booking
                history, your profile information and preferences, and other
                content you submit, and
              </p>
            </li>
            <li>
              <p>
                x. enable your use of our products and accommodation services.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              2.2 Safeguard the Settle Platform and Community.
            </strong>{" "}
            We may process this information to:
          </p>
          <ul>
            <li>
              <p>
                i. study and combat discrimination consistent with our
                Nondiscrimination Policy,
              </p>
            </li>
            <li>
              <p>
                ii. detect, prevent, assess, and address fraud and security
                risks,
              </p>
            </li>
            <li>
              <p>
                iii. verify or authenticate information provided by you,
                including identity information, as described in Information
                Needed to Use the Settle Platform,
              </p>
            </li>
            <li>
              <p>
                iv. conduct checks against databases and other information
                sources, including background checks,
              </p>
            </li>
            <li>
              <p>
                v. resolve disputes with our Members, including sharing
                information with your co-Tenant(s) or additional Tenants about
                disputes related to your role as a co-Landlord(s) or additional
                Tenants,
              </p>
            </li>
            <li>
              <p>vi. enforce our agreements with third parties,</p>
            </li>
            <li>
              <p>
                vii. determine eligibility for certain types of rentals, such as
                Instant Rentals,
              </p>
            </li>
            <li>
              <p>
                viii. comply with law, respond to legal requests, prevent harm,
                and protect our rights (see Comply with Law, Respond to Legal
                Requests, Prevent Harm, and Protect Our Rights),
              </p>
            </li>
            <li>
              <p>
                enforce our Terms and other policies, such as our
                Nondiscrimination Policy, and assess or evaluate your
                interactions with the Settle Platform and information obtained
                from third parties.
              </p>
            </li>
          </ul>
          <p>
            In certain cases, automated processes, which analyze your account
            and activities on the Settle platform as well as information in
            relation to activities on and off the Settle platform that can be
            associated with you, could restrict or suspend your access to the
            Setttle Platform if such processes detect activity that may pose a
            safety or other risk to Settle, our community, or third parties.
            Learn more about how our system determines whether certain
            reservations may carry a higher risk for incidents here. If you
            would like to challenge decisions based on automated processes,
            please contact us via the Contact Information section.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              2.3 Analyze and Share Your Communications.
            </strong>{" "}
            We may review, scan, or analyze your communications on the Settle
            Platform for reasons outlined in the “How We Use Information We
            Collect” section of this policy, including fraud prevention,
            security investigations, risk assessment, regulatory compliance,
            product development, research, analytics, enforcing our Terms of
            Service, and customer support purposes. For example, as part of our
            fraud prevention efforts, we scan and analyze messages to mask
            contact information and references to other sites and, subject to
            applicable law, we scan and analyze all images uploaded by users to
            the Settle platform in message threads, profiles, listings, and
            experiences for certain illegal or inappropriate activities – such
            as evidence of child exploitation – for the purpose of identifying
            and reporting content violations to appropriate authorities. In some
            cases, we may also scan, review, or analyze messages to debug,
            improve, and expand product offerings. We use automated methods
            where reasonably possible. Occasionally we may need to manually
            review communications, such as for fraud investigations and customer
            support, or to assess and improve the functionality of these
            automated tools. We will not review, scan, or analyze your messaging
            communications to send third-party marketing messages to you and we
            will not sell reviews or analyses of these communications. We may
            also share your communications as set out in the “Sharing and
            Disclosure” section.{" "}
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>2.4 Provide Payment Services.</strong> Personal information is used to
            enable, or authorize third parties to use, Payment Services, such as
            to:
          </p>
          <ul>
            <li>
              <p>
                i. detect and prevent money laundering, fraud, abuse, and
                security incidents, as well as conduct risk assessments,
              </p>
            </li>
            <li>
              <p>
                ii. comply with legal and compliance obligations, such as
                anti-money laundering regulations and sanctions enforcement,
              </p>
            </li>
            <li>
              <p>iii. enforce the Payment Terms and other payment policies,</p>
            </li>
            <li>
              <p>iv. and provide and improve Payment Services.</p>
            </li>
          </ul>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              3. SHARING AND DISCLOSURE.
            </strong>{" "}
            We rely on for transferring personal information to recipients
            outside of certain jurisdictions here.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.1 Sharing With Your Consent or at Your Direction.</strong> Where you
            provide consent or direct us to share your information, we share
            your information as described at the time of consent or choice, such
            as when authorizing a third-party application or website to access
            your Settle account, making payment, or participating in promotional
            activities by Settle.
          </p>
        </div>
        <div>
          <p><strong style={{ color: "black", fontWeight: "bold" }}>3.2 Who We Share With.</strong> We may share your information with:</p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.2.1 Other Members.</strong> To help facilitate rentals or other
            interactions between Members (who may be located in, or use service
            providers located in, jurisdictions with varying levels of data
            protection), we may share information in certain situations, as
            described in Sharing and disclosing information with other Members.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.2.2 Building Management.</strong> We may share personal information of
            Landlords and Tenants with the owner(s) of an Accommodation, a
            homeowners’ association, and/or their agent, such as a building
            operator or property management company (“Building Management”),
            including rental information and information related to compliance
            with applicable laws, in order to facilitate programs with Building
            Management. Tenant rentals and personal information, including
            tenants contact information, may be shared with the Building
            Management of the building, complex, or community where a landlord
            lives and/or the listing is located to facilitate renting services,
            compliance with applicable laws, security, billing, and other
            services.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.2.6 Corporate Affiliates.</strong> To support us in providing, integrating,
            promoting and improving the Settle Platform, Payment Services, and
            our affiliates’ services, we may share personal information within
            our corporate family of companies, including:
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.2.6(i) Settle App Limited.</strong> Even if your country of residence is
            not the Nigeria, your information will be shared with Settle App
            Limited, which provides the technical infrastructure for the Settle
            Platform.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.3 Why We May Share Your Information.</strong> We may share your information
            in order to:
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.3.1 Build Your Public Profile.</strong> Information you share publicly on
            the Settle Platform may be indexed through third-party search
            engines. In some cases, you may opt-out of this sharing in your
            account settings. We may make certain information publicly visible
            to others, such as:
          </p>
          <ul>
            <li>
              <p>
                i. your profile and account information and information derived
                from them, as described here,
              </p>
            </li>
            <li>
              <p>
                ii. listing pages that include information such as the
                Accommodation or Experience’s approximate or precise location
                description, calendar availability, profile photo, aggregated
                demand information, and additional information you choose to
                share,
              </p>
            </li>
            <li>
              <p>iii. reviews, ratings, and other public feedback, and</p>
            </li>
            <li>
              <p>
                iv. content in a community or discussion forum, blog, or social
                media post, and content you make available to the public,
                including Listing details on third-party sites, platforms, and
                apps.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.3.2 Comply with Law, Respond to Legal Requests, Prevent Harm, and
            Protect Our Rights.</strong>
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.3.2(i) Disclosure.</strong> We may disclose your information to courts, law
            enforcement, governmental or public authorities, tax authorities,
            authorized third parties, or other Members, if and to the extent we
            are required or permitted to do so by law or where disclosure is
            reasonably necessary to: (i) comply with our legal obligations, (ii)
            comply with a valid legal request, such as a subpoena or court
            order, or to respond to claims asserted against Settle, (iii)
            respond to a valid legal request relating to a criminal
            investigation to address alleged or suspected illegal activity, or
            to respond to or address any other activity that may expose us, you,
            or any other of our users to legal or regulatory liability (more
            information on Settle’s Law Enforcement Guidelines here), (iv)
            enforce and administer our agreements with Members, including our
            Terms, Additional Legal Terms, and Policies, (v) respond to requests
            for or in connection with current or prospective legal claims or
            legal proceedings concerning Settle and/or third parties, in
            accordance with applicable law, or (vi) protect the rights, property
            or personal safety of Settle, its employees, its Members, or members
            of the public.
          </p>
        </div>
        <div>
          <p>
            3.3.2(i)(a) To Tax Authorities. Where legally required or
            permissible according to applicable law, we may disclose Landlord’s
            and/or Tenants information to relevant tax authorities or other
            governmental agencies, depending on where you are based, for the
            purpose of the tax authorities’ determination of proper compliance
            with relevant tax obligations. For more information, visit our Help
            Center.
          </p>
        </div>
        <div>
          <p>
            3.3.2(i)(b) To Governmental Authorities for Registrations,
            Notifications, Permits, or License Applications or Numbers. In
            jurisdictions where Settle facilitates or requires a registration,
            notification, permit, or license application or number of a Host
            with a governmental authority, we may share information of
            participating Landlords with the relevant authority, during the
            application process, when the Listing is published, and periodically
            thereafter.
          </p>
        </div>
        <div>
          <p>
            3.3.2(ii) Notification. Where appropriate and/or legally required,
            we may notify Members about legal requests, unless: (i) providing
            notice is prohibited by the legal process itself, by court order we
            receive, or by applicable law, or (ii) we believe that providing
            notice would be futile, ineffective, create a risk of injury or
            bodily harm to an individual or group, or create or increase a risk
            of fraud upon or harm to Settle, our Members, or expose Settle to a
            claim of obstruction of justice.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>3.3.3 Effectuate Business Transfers.</strong> If Settle undertakes or is
            involved in any merger, acquisition, reorganization, sale of assets,
            bankruptcy, or insolvency event, then we may sell, transfer, or
            share some or all of our assets, including your information in
            connection with such transaction or in contemplation of such
            transaction, such as due diligence. In this event, we will notify
            you before your personal information is transferred and becomes
            subject to a different privacy policy.
          </p>
        </div>
        <div>
          <p style={{ color: "black", fontWeight: "bold" }}>
            4. THIRD-PARTY PARTNERS AND INTEGRATIONS.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>4.1 Third-Party Service Terms.</strong> Parts of Settle may link to
            third-party services. Settle does not own or control these third
            parties. When you interact with these third parties and choose to
            use their service, you are providing your information to them. Your
            use of these services is subject to the privacy policies of those
            providers, including Google Maps/Earth Additional Terms of Use,
            Google Privacy Policy (see here for more information on how Google
            uses information), Paystack Privacy Statement, and Firebase Privacy
            Policy.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5. YOUR RIGHTS.
            </strong>{" "}
            You can exercise any of the rights described in this section
            consistent with applicable law. See here for information on data
            subject rights requests and how to submit a request. We may ask you
            to verify your identity and request before taking further action on
            your request.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.1 EU and Brazil Residents.
            </strong>{" "}
            Learn more about rights under the General Data Protection Regulation
            (“GDPR”) and the Brazil General Data Protection Law (“LGPD”) here.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.2 Managing Your Information.
            </strong>{" "}
            You can access and update some of your personal information through
            your Account settings. you are responsible for keeping your personal
            information up to date.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.4 Access and Portability.
            </strong>{" "}
            In some jurisdictions, applicable law may entitle you to request
            certain copies of your personal information or information about how
            we handle your personal information, request copies of personal
            information that you have provided to us in a structured, commonly
            used, and machine-readable format, and/or request that we transmit
            this information to another service provider, where technically
            feasible.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.5 Erasure. In some jurisdictions, you can request that your
              personal information be deleted.
            </strong>{" "}
            Please note that if you request deletion of your personal
            information, or if your account is suspended, terminated, or
            voluntarily closed:
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.5.1 Legitimate Business Interests.
            </strong>{" "}
            We may retain your personal information as necessary for our
            legitimate business interests, such as prevention of money
            laundering, fraud detection and prevention, and enhancing safety.
            For example, if we suspend a Settle Account for fraud or safety
            reasons, we may retain information from that Settle Account to
            prevent that Member from opening a new Settle Account in the future.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.5.2 Legal, Tax, Reporting, and Auditing Obligations.
            </strong>{" "}
            We may retain and use your personal information to the extent
            necessary to comply with our legal, tax, reporting, and auditing
            obligations.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.5.3 Shared Information.
            </strong>{" "}
            Information you have shared with others, such as Reviews and forum
            postings, may continue to be publicly visible on Settle, even after
            your Settle Account is canceled.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              5.5.4 Residual Copies.
            </strong>{" "}
            Because we take measures to protect data from accidental or
            malicious loss and destruction, residual copies of your personal
            information may not be removed from our backup systems for a limited
            period of time.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              6. SECURITY.
            </strong>{" "}
            While no organization can guarantee perfect security, we are
            continuously implementing and updating administrative, technical,
            and physical security measures to help protect your information
            against unlawful or unauthorized access, loss, destruction, or
            alteration.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              7. CHANGES TO THIS PRIVACY POLICY.
            </strong>{" "}
            We reserve the right to modify this Privacy Policy at any time in
            accordance with applicable law. If we do so, we will post the
            revised Privacy Policy and update the “Last Updated” date at the
            top. In case of material changes, we will also provide you with
            notice of the modification by email at least thirty (30) days before
            the effective date. If you disagree with the revised Privacy Policy,
            you can cancel your Account. If you do not cancel your Account
            before the date the revised Privacy Policy becomes effective, your
            continued access to or use of the Settle Platform will be subject to
            the revised Privacy Policy.
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              8. CONTACT INFORMATION AND RESPONSIBLE SETTLE ENTITIES.
            </strong>{" "}
            For questions or complaints about this Privacy Policy or Settle’s
            handling of personal information (i) If you reside in Nigeria
            contact Settle App Limited, No. 2 Aliade Cl, Garki or by emailing us
            at{" "}
            <a href="mailto:hello@settleafrica.com">hello@settleafrica.com</a>{" "}
            or by calling us at{" "}
            <a href="tel:+2349160111135">(+234) 916-011-1135.</a>
          </p>
        </div>
        <div>
          <p>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              9. DEFINITIONS.
            </strong>{" "}
            Undefined terms in this Privacy Policy have the same definition as
            in our Terms of Service (“Terms”).
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
