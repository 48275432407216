

const Anchor = ({ className}) => {
  return (
    <svg className={className} width="1319" height="718" viewBox="0 0 1319 718" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.921875" y="0.5" width="1317.58" height="717" stroke="#151515"/>
    </svg>
  )
};

export default Anchor; 